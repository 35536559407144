define("discourse/plugins/discourse-custom-topic-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class="btn custom-topic-button"
    title={{siteSettings.custom_topic_button_title}}
    {{action "clickButton"}}>{{siteSettings.custom_topic_button_label}}</button>
  
  */
  {
    "id": "9bXOoiDW",
    "block": "[[[44,[[28,[37,1],null,[[\"siteSettings\"],[[28,[32,0],[\"siteSettings\"],null]]]]],[[[11,\"button\"],[24,0,\"btn custom-topic-button\"],[16,\"title\",[52,[30,1,[\"siteSettings\"]],[28,[30,1,[\"siteSettings\"]],null,null],[28,[32,1],[[30,0],\"siteSettings.custom_topic_button_title\",\"[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-custom-topic-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[4,[38,3],[[30,0],\"clickButton\"],null],[12],[1,[30,0,[\"siteSettings\",\"custom_topic_button_label\"]]],[13]],[1]]],[1,\"\\n\"],[1,[28,[32,2],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-custom-topic-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"if\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-topic-button/discourse/templates/connectors/after-topic-footer-main-buttons/custom-public-button.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});