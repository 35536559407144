define("discourse/plugins/discourse-custom-topic-button/discourse/connectors/after-topic-footer-main-buttons/custom-public-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      clickButton() {
        const url = this.siteSettings.custom_topic_button_url.replace('<TOPIC_ID>', this.get('topic.id')).replace('<USER_ID>', this.currentUser.id).replace('<USERNAME>', this.currentUser.username).replace('<TOPIC_TITLE>', this.get('topic.title')).replace('<TOPIC_SLUG>', this.get('topic.slug'));
        window.open(url, '_blank');
      }
    }
  };
});